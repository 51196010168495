@import './../../../../scss/theme-bootstrap';

.basic-formatter {
}

.basic-formatter__header {
  padding: 0 20px;
  margin: 0 0 15px 0;
  @include breakpoint($medium-up) {
    padding: 0 60px;
    margin-bottom: 20px;
  }
}

.basic-formatter__title,
.basic-formatter__description {
  margin: 0;
  > p {
    margin: 0;
    line-height: inherit;
  }
}

.basic-formatter__description {
  margin-top: 5px;
}

.basic-formatter__footer {
  margin: 10px 0 0 0;
  padding: 0 10px;
  @include breakpoint($medium-up) {
    margin-top: 20px;
    padding: 0 35px;
  }
  .basic-formatter__button {
    @include extended-link;
    &.button {
      border-width: 1px;
      color: $color-white;
      @include breakpoint($small-only) {
        width: 100%;
      }
      &.button--inverted {
        color: $color-black;
      }
      &.button--large {
        font-size: 19px;
      }
    }
  }
}

.basic-formatter {
  .breadcrumb__container {
    @include clearfix;
    height: 40px;
    border-bottom: 1px solid $color-light-gray;
    @include breakpoint($medium-up) {
      border-bottom: none;
    }
  }
  .breadcrumb__link {
    display: inline-block;
    font-family: $font--avenir-heavy;
    font-size: 12px;
    line-height: 40px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .hide-landing-mobile .breadcrumb__link__landing {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .breadcrumb__link__landing {
    @include icon('arrow_thin_previous-back');
    @include swap_direction(margin, 0 0 0 30px);
    &:before {
      margin-#{$rdirection}: 10px;
    }
    @include breakpoint($medium-up) {
      display: inline-block;
      float: #{$ldirection};
      margin-#{$ldirection}: 40px;
      &:before {
        margin-#{$rdirection}: 10px;
      }
    }
  }
  .breadcrumb__link__next {
    @include icon('arrow_thin_next-forward', after);
    float: #{$rdirection};
    margin-#{$rdirection}: 30px;
    &:after {
      margin-#{$ldirection}: 10px;
    }
    @include breakpoint($medium-up) {
      margin-#{$rdirection}: 40px;
    }
  }
  .breadcrumb__link__previous {
    @include icon('arrow_thin_previous-back');
    float: #{$ldirection};
    @include swap_direction(margin, 0 0 0 30px);
    &:before {
      margin-#{$rdirection}: 10px;
    }
    @include breakpoint($medium-up) {
      float: #{$rdirection};
      @include swap_direction(margin, 0 25px 0 0);
    }
  }
}

.cleanse-mobile {
  .basic-tout__content {
    width: 64%;
    #{$ldirection}: auto;
    #{$rdirection}: 0;
    padding: 0 4%;
  }
  .cleanse-condition {
    margin-bottom: 25px;
  }
}

.treat-mobile {
  .basic-tout {
    &__content {
      width: 64%;
      padding: 0 4%;
    }
  }
  .treat {
    margin-bottom: 25px;
  }
  .product-grid {
    &__item {
      width: 100%;
    }
  }
}

.prestyle-mobile {
  .basic-tout {
    &__content {
      width: 64%;
      padding: 0 4%;
      #{$ldirection}: auto;
      #{$rdirection}: 0;
    }
  }
  .pre-style {
    margin-bottom: 25px;
  }
  .product-grid {
    &__item {
      width: 100%;
    }
  }
}
